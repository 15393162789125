import "./src/styles/global.scss";

if (window.CivicCookieConfig) {
  window.CivicCookieConfig.onConsentChange((consent) => {
    // Example: Send an event to GTM when consent changes
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "civicConsentUpdate",
        consentData: consent,
      });
    }
  });
}

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Check if the script is already loaded
    if (!document.getElementById("civic-cookie-script")) {
      const script = document.createElement("script");
      script.id = "civic-cookie-script";
      script.src =
        "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"; // Replace with the actual Civic script URL
      script.setAttribute("data-civic-config", "/civic-config.json"); // Replace with your config file path
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        console.log("Civic Cookie Consent script loaded");
      };
    }
  }

  if (typeof window !== "undefined") {
    const config = {
      apiKey: "d6dce2d00743cc45211a5d8c8102e0f1da818738",
      product: "community",
      optionalCookies: [
        {
          name: "analytics",
          label: "Analytics",
          description: "",
          cookies: [],
          onAccept: function () {},
          onRevoke: function () {},
        },
        {
          name: "marketing",
          label: "Marketing",
          description: "",
          cookies: [],
          onAccept: function () {},
          onRevoke: function () {},
        },
        {
          name: "preferences",
          label: "Preferences",
          description: "",
          cookies: [],
          onAccept: function () {},
          onRevoke: function () {},
        },
      ],
      position: "RIGHT",
      theme: "DARK",
    };

    window.CookieControl && window.CookieControl.load(config);
  }
};
