exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bid-writing-bid-writer-cleaning-contracts-js": () => import("./../../../src/pages/bid-writing/bid-writer-cleaning-contracts.js" /* webpackChunkName: "component---src-pages-bid-writing-bid-writer-cleaning-contracts-js" */),
  "component---src-pages-bid-writing-construction-bid-writing-js": () => import("./../../../src/pages/bid-writing/construction-bid-writing.js" /* webpackChunkName: "component---src-pages-bid-writing-construction-bid-writing-js" */),
  "component---src-pages-bid-writing-health-and-social-care-bid-writer-js": () => import("./../../../src/pages/bid-writing/health-and-social-care-bid-writer.js" /* webpackChunkName: "component---src-pages-bid-writing-health-and-social-care-bid-writer-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

